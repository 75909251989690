import { Routes, Route } from "react-router-dom";
import { Home } from './pages/Home';
import { Pricing } from "./pages/Pricing";
import { Contact } from "./pages/Contact";
import Cookies from "./pages/Cookies";

import { useLocation } from 'react-router-dom';

import Header from './components/Header';
import Footer from './components/Footer';
import ProjectCTA from './components/ProjectCTA';
// import ThemeSwitcher from './components/ThemeSwitcher';
import ScrollToTop from './components/ScrollToTop';

// import { GTMProvider } from '@elgorditosalsero/react-gtm-hook';

import Router from './Router';

function App() {
  // const gtmParams = {
  //   id: 'GTM-KWNVL8X',
  //   dataLayerName: 'GTM-KWNVL8X',
  // }
  return (
    <>
      <>

      {/*[if lte IE 9]>
        <p class="browserupgrade">You are using an <strong>outdated</strong> browser. Please <a href="https://browsehappy.com/">upgrade your browser</a> to improve your experience and security.</p>
      <![endif]*/}
      <ScrollToTop />

      {/* <div className="my_switcher d-none d-lg-block"> */}
        {/* <ThemeSwitcher></ThemeSwitcher> */}
      {/* </div> */}
      <div id="main-wrapper" className="main-wrapper">
        <Header />

        <Router />

        {useLocation().pathname !== "/danke" ?
          <>
          <ProjectCTA />
          <Footer />
          </>
          :
          <></>
        }
      </div>

      </>
    </>
  );
}

export default App;
